import React from "react";
import Seo from "../../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
// import ogImage from "../../images/og.png";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import BlogCtaBanner from "../../components/BlogCtaBanner";

const WITDreamEvent = ({ location, data }) => {
    const WITDreamEventPage = useStaticQuery(graphql`
        {
            eventPage6: contentfulEventPages(slug: { eq: "wit-dreamin-2024" }) {
                id
                slug
                title
                description {
                    description
                }
                meetusCtaText
                eventBannerImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                }
                eventClientHeading
                eventClientDescription {
                    eventClientDescription
                }
                eventClientsLogo {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                eventTitle
                eventDescription {
                    eventDescription
                }
                chatSectionTitle
                chatSectionDescription {
                    raw
                }
                meetOurTeamTitle

                eventVenueAddress {
                    eventVenueAddress
                }
                eventVenueDate
                eventVenueImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                eventFooter {
                    ctaText
                    header
                    ctaSlug
                }

                eventOgImage {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                }
            }
        }
    `);
    const eventData = WITDreamEventPage.eventPage6;
    // console.log(eventData);
    const bgImage = eventData.eventBannerImage?.gatsbyImageData?.images.fallback.src;
    const ogImage = eventData.eventOgImage?.gatsbyImageData?.images.fallback.src;
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const options = {
        renderNode: {
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => {
                return <ol>{children}</ol>;
            },
            [BLOCKS.LIST_ITEM]: (node, children) => {
                return (
                    <li className="text-p3 text-clr-secondary text-fw-regular gap-1x">
                        {children}
                    </li>
                );
            },
        },
    };
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Seo
                title={" Events - WIT Dreamin' 2024"}
                description={
                    "Women In Tech Dreamin' is a groundbreaking event dedicated to empowering women & allies in the tech industry. This vibrant platform offers inspirational keynotes, immersive workshops & dynamic networking opportunities."
                }
                url={location.href}
                image={ogImage}
            />
            {/* event-details-landing page-hero section */}
            <section
                className="events-details-hero"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="event-details__text">
                        <h1 className="text-h1 title text-fw-medium">{eventData.title}</h1>
                        <div className="event-details__text-partner">
                            <div className="partner-summit-one">
                                <h3 className="text-p1 text-fw-regular date">
                                    October 26 <span> | </span> {eventData.description?.description}
                                </h3>
                            </div>
                        </div>
                        <div className="flex">
                            <Link className="meet-at sdn-cta-btn" to="/contact/">
                                {eventData.meetusCtaText}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="event-details-client">
                <div className="container container--first">
                    <div className="event-details-clients__grid">
                        <div className="event-details-clients__grid-text-block">
                            <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                                {eventData.eventClientHeading}
                            </h1>
                            <p className="text-p2 text-clr-secondary text-fw-regular gap-4x">
                                {eventData.eventClientDescription.eventClientDescription}
                            </p>
                        </div>
                        <div className="event-details-clients__grid-logo">
                            {eventData.eventClientsLogo.map((logo, i) => (
                                <GatsbyImage
                                    key={i}
                                    image={logo.gatsbyImageData}
                                    alt={logo.title}
                                    className="client-logo"
                                    loading="lazy"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {/* we are proud sponser section */}
            <section className="event-details-proudsponser">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            {eventData.eventTitle}
                        </h1>
                        <p className="text-p2 text-clr-secondary text-fw-regular gap-2x">
                            {eventData.eventDescription.eventDescription}
                        </p>
                    </div>
                </div>
            </section>
            {/* our speakers section  */}
            {/* <section className="event-our-speakers">
                <div className="container container--mid-v4">
                    <div className="event-our-speakers__text">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            {eventData.eventSpeakerTitle}
                        </h1>
                        <p className="text-p2 text-clr-secondary text-fw-regular gap-2x">
                            {eventData.eventSpeakerDescription.eventSpeakerDescription}
                        </p>
                    </div>
                    <div className="leaders__grid">
                        {eventData.eventSpeakerMembers.map((speaker, i) => (
                            <div key={i} className="leaders__grid-item">
                                <GatsbyImage
                                    image={speaker.avatar?.gatsbyImageData}
                                    alt={speaker.avatar.title}
                                    className="leader-image"
                                    loading="lazy"
                                />
                                <p className="text-p1 leader-name text-clr-primary text-fw-medium">
                                    {speaker.name}
                                </p>
                                <p className="text-p4 text-clr-secondary text-fw-medium">
                                    {speaker.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* group chat points section */}
            <section className="event-details-group-chat-point">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            {eventData.chatSectionTitle}
                        </h1>
                        {renderRichText(eventData.chatSectionDescription, options)}
                    </div>
                </div>
            </section>
            {/* meet out team section */}
            {/* <section className="event-details-meet-our-team">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            {eventData.meetOurTeamTitle}
                        </h1>
                    </div>
                    <div className="leaders__grid">
                        {eventData.eventMembers.map((leader, i) => (
                            <div className="leaders__grid-item" key={i}>
                                <GatsbyImage
                                    image={leader.colorLogo?.gatsbyImageData}
                                    alt={leader.colorLogo?.title}
                                    className="leader-image"
                                    loading="lazy"
                                />
                                <p className="text-p1 leader-name text-clr-primary text-fw-medium">
                                    {leader.name}
                                </p>
                                <p className="text-p4 text-clr-secondary text-fw-medium">
                                    {leader.designation}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* venue section */}
            <section className="event-details-venue">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <div className="event-details__text-grid">
                            <div className="venue-address">
                                <h1 className="text-h1 text-fw-medium text-clr-primary gap-1x">
                                    Venue
                                </h1>
                                <div className="venue-address-main">
                                    <p className="text-p4 venue-address-street">
                                        {eventData.eventVenueAddress.eventVenueAddress}
                                    </p>
                                </div>
                                <div className="venue-address-bottom">
                                    <p className="text-p4 venue-address-date">
                                        {eventData.eventVenueDate}
                                    </p>
                                </div>
                            </div>
                            <div className="venue-img">
                                <GatsbyImage
                                    image={eventData.eventVenueImage?.gatsbyImageData}
                                    alt={eventData.eventVenueImage?.title}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* service section  */}
            <section className="service-contact">
                <div className="container container--mid-v4">
                    <BlogCtaBanner
                        title={eventData.eventFooter.header}
                        ctaLink={eventData.eventFooter.ctaSlug}
                        ctaText={eventData.eventFooter.ctaText}
                    />
                </div>
            </section>
        </Layout>
    );
};

export default WITDreamEvent;
